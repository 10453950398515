import { isNaN } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.resizeObserver = new ResizeObserver(this.updateHeight.bind(this));
  }

  connect() {
    this.updateHeight();
    this.resizeObserver.observe(this.element);
  }

  disconnect() {
    this.resizeObserver.unobserve(this.element);
    this.cssHeightVariable = null;
  }

  updateHeight() {
    if (this.position !== 'fixed') {
      if (this.cssHeightVariable !== 0) {
        this.cssHeightVariable = 0;
        this.dispatchOnDocument('resize', { detail: 0 });
      }

      return;
    }

    if (this.cssHeightVariable === this.height) return;

    this.cssHeightVariable = this.height;
    this.dispatchOnDocument('resize', { detail: this.height });
  }

  get position() {
    return window.getComputedStyle(this.element).getPropertyValue('position');
  }

  get height() {
    return this.element.offsetHeight;
  }

  get cssHeightVariable() {
    const height = parseInt(document.documentElement.style.getPropertyValue('--header-height'), 10);

    if (isNaN(height)) {
      return 0;
    }

    return height;
  }

  set cssHeightVariable(value) {
    if (!value) {
      document.documentElement.style.removeProperty('--header-height');
      return;
    }

    document.documentElement.style.setProperty('--header-height', `${value}px`);
  }
}
