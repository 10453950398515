import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import localizeFlatpickr from 'integration/flatpickr/localize_flatpickr';
import localizeMoment from 'integration/moment/localize_moment';
import startRails from 'integration/rails/start_rails';

if (isVariableDefinedNotNull(window.gon) && isVariableDefinedNotNull(window.gon.locale)) {
  localizeFlatpickr(window.gon.locale);
  localizeMoment(window.gon.locale);
}

startRails();
